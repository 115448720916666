<template>
  <app-center>
    <template v-slot:page>
      <h1 class="text-h3 primary--text">
        {{ `${$t('errors.whoops')}, ${errorCode}` }}
      </h1>
      <p>{{ $t(`errors.${errorCode}`) }}</p>
      <v-btn
        outlined
        color="primary"

        @click="$router.push('/dashboard')"
      >
        {{ $t('errors.home') }}
      </v-btn>
    </template>
  </app-center>
</template>

<script>
import AppCenter from '@/views/widget/AppCenter.vue';

export default {
  name: 'ErrorPage',
  components: {
    AppCenter,
  },
  props: {
    errorCode: {
      type: Number,
      default: 404,
    },
  },
  data: () => ({}),
  created() {
    // @click="$router.go(-1)"
    console.log('DailyEnergy');
  },
};
</script>
